import React, { Component } from 'react';
import { connect } from 'react-redux';  // gets state data from store in provider  
import { selectSong, scannedItems } from '../actions' // import the named exported action creator from index.js in the actions folder 

class TaskList extends Component {
    renderList() {
        // use this.props in class compoents 
        return this.props.tasks.map((task) => { // the outter return returns an array jsx elements for the mapping function
            return (    // inner return returns a jsx per item that makes use of properties in the state  

                // class names in the jsx below are from the semantics ui css library called in index.html in the public folder
                <div className="item" key={task.email}>
                    <div className="right floated content">

                    </div>
                    <div className="content">{task.email}</div>
                </div>
            );
        })
    }

    render() {
        //console.log(this.props) 
        //this.props ==={songs: state.songs}


        return <div className="ui divided list"> {this.renderList()}</div>;
    }
}
// the mapStateToProps function receives state change updates and maps state objects to component props. Can be named anything: 
const mapStateToProps = (state) => {
    //console.log(state); //console log the entire state object to view changes as the occur
    //return state; 
    // returns the state.songs property to the component as props.songs with an array of songs
    console.log(state);
    console.log(state.tasks)
    return { tasks: state.scanned }
}

export default connect(mapStateToProps, { scannedItems: scannedItems })(TaskList);
//export default connect(mapStateToProps, { selectSong })(SongList); // can be shorted to this per es15 rules
