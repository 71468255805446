import React from 'react'
import {
  Router,
  Route
} from 'react-router-dom'
import Callback from './routes/Callback'
import Home from './routes/Home'
import TaskList from './components/TaskList'

import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const App = () => (
  <Router history={history}>
    <Route exact path="/" component={Home} />
    <Route exact path="/callback" component={Callback} />
    <Route exact path="/task" component={TaskList} />

  </Router>
)

export default App
