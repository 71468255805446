
/* export const fetchPosts = () => async (dispatch) => {
    const response = await jsonPlaceholder.get('/posts');
    //console.log(response.data)
    dispatch({ type: 'FETCH_POSTS', payload: response.data })
} */
export const scannedItems = () => async (dispatch) => {
    var request = fetch('https://7043f2z5a2.execute-api.us-east-1.amazonaws.com/prod/ergon/scan', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'yDWGplLHQS9yDTGB4a1aX8I1fMOSXGKV4Bl5Fbzq',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => console.log('Error while fetching:', error));
    console.log(request)
    dispatch({ type: 'SCANNED_TASK', payload: request })
}
/* export function scannedItems() {
    var request = fetch('https://7043f2z5a2.execute-api.us-east-1.amazonaws.com/prod/ergon/scan', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'yDWGplLHQS9yDTGB4a1aX8I1fMOSXGKV4Bl5Fbzq',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => console.log('Error while fetching:', error));
    return {
        type: SCANNED_TASK,
        payload: request,
    };
} */